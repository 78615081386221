import React, { useEffect, useState } from 'react'
import { db } from '../config/firebase-config';
import Auth from './Auth';
import { getDocs, collection, addDoc, deleteDoc, doc } from "firebase/firestore"

function Users() {
    const [Users, setUsers] = useState([]);
    const UsersCollectionRef = collection(db, "users")

    const getUsers = async () => {
        //? Read the data;
        try {
            const data = await getDocs(UsersCollectionRef)
            const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            setUsers(filteredData)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    /*     const onSubmitMovie = async () => {
            try {
                const movieDoc = { title: movieTitle, releaseYear: movieYear, hasOscar: movieOscar }
                await addDoc(UsersCollectionRef, movieDoc)
                await getUsers();
            } catch (error) {
                console.error(error)
            }
    
        }
    
        const deleteMovie = async (id) => {
            try {
                const movieDoc = doc(db, "Users", id)
                await deleteDoc(movieDoc)
                await getUsers()
            } catch (error) {
                console.error(error)
            }
        } */

    return (
        <section>
            <div className="tbl-header">
                <table cellPadding={0} cellSpacing={0} border={0}>
                    <thead>
                        <tr>
                            <th>İsim</th>
                            <th>İnstagram</th>
                            <th>Sınıf</th>
                            <th>Email</th>
                            <th>Telefon</th>
                            <th>Notlar</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tbl-content">
                <table cellPadding={0} cellSpacing={0} border={0}>
                    <tbody>
                        {Users.map((user) => (
                            <tr>
                                <td>{user.name}</td>
                                <td>{user.instagram}</td>
                                <td>{user.classroom}</td>
                                <td>{user.email}</td>
                                <td>{user.phone_number}</td>
                                <td>{user.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default Users