import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getFirestore } from "firebase/firestore"


const firebaseConfig = {
    apiKey: "AIzaSyDJT1Nwsik2BzkMVRUxvkxDeUgHVw8wdbA",
    authDomain: "baalgirisim.firebaseapp.com",
    projectId: "baalgirisim",
    storageBucket: "baalgirisim.appspot.com",
    messagingSenderId: "134478114607",
    appId: "1:134478114607:web:0113fb5ad0d372b1dafbda"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);

